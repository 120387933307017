import React, {useEffect, useMemo, useState} from "react";
import 'react-toastify/dist/ReactToastify.css';
import { Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown, UncontrolledTooltip } from "reactstrap";
import Spinners from "Components/Common/Spinner";
import AnnouncementModal from "Components/Modal/AnnouncementModal";

//import components
import Breadcrumbs from '../../../Components/Common/Breadcrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import {addAnnouncement, deleteAnnouncement, getAnnouncements, getManagers, updateAnnouncement, uploadFiles, uploadImageOnS3} from "helpers/backend_helper";
import { toast, ToastContainer } from 'react-toastify';
import RoleCell from "../../../Components/Common/RoleCell";
import InviteModal from "../../../Components/Modal/InviteModal";
import DeleteModal from "Components/Modal/DeleteModal";
import { Link } from "react-router-dom";
import { convertUTC2Local, convertUTCDateToInputFormat } from "utils";
import {userManager} from "../../../utils/UserManager";

interface column {
    header: string,
    accessorKey: string,
    enableColumnFilter: boolean,
    enableSorting: boolean,
    cell?: (cellProps: any) => JSX.Element;
}

const currentUser = userManager;
const AnouncementsPage = ({title}) => {
    const [managersData, setManagersData] = useState([]);
    const [announcementsData, setAnnouncementsData] = useState<any>([]);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [newModalIsOpen, setNewModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [valueToBeModified, setValueToBeModified] = useState<any>();

    function toggleNewModal() {
        setNewModalIsOpen(!newModalIsOpen);
        removeBodyCss();
    }

    function toggleEditModal() {
        setEditModalIsOpen(!editModalIsOpen);
        removeBodyCss();
    }

    function toggleDeleteModal () {
        setDeleteModalIsOpen(!deleteModalIsOpen);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const fetchAnnouncements = async () => {
        try {
            const response = await getAnnouncements(1); // Add parameters if needed
            if (response.success) {

                setAnnouncementsData(response.data.announcements);
            } else {
                // Display error notification
                toast.error(response.error || "Failed to fetch announcements.");
            }
        } catch (error) {

            toast.error("Failed to fetch announcements.");
        }
    };

    const handleCreateAnnouncement = (newAnnouncement: any, files: File[] = []) => {
        const createAnnouncement = async () => {
            try {
                const announcementPayload = {
                    title: newAnnouncement.title,
                    description: newAnnouncement.description
                }

                if (newAnnouncement.mediaUri) {
                    announcementPayload['mediaUri'] = newAnnouncement.mediaUri
                }

                const response = await addAnnouncement(newAnnouncement); // Add parameters if needed
                if (response.success) {

                    setAnnouncementsData((state) => [...state, response.data]);
                    const announcementId = response.data.id;

                    console.log(files.length)

                    if (files.length) {
                        // request signed upload url
                        const uploads = [
                            {
                                resourcePath: `announcements/${announcementId}`,
                                fileName: files[0]['name']
                            }
                        ]

                        const response = await uploadFiles(uploads);

                        if (response.success) {
                            const putUrl = response.data.uploads?.[0]?.putUrl

                            uploadImageOnS3(putUrl, files[0])
                                .then(() => {
                                    fetchAnnouncements();
                                });
                        }
                    }

                    toast.success("Created new announcement.");
                    toggleNewModal();
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to create announcement.");
                }
            } catch (error) {

                toast.error("Failed to create announcement.");
            }
        };

        createAnnouncement();
    }

    const handleEditAnnouncement = (announcement: any, files: File[]) => {
        const modifyAnnouncement = async () => {
            try {
                const announcementPayload = {
                    id: announcement.id,
                    title: announcement.title,
                    description: announcement.description
                }

                if (announcement.mediaUri) {
                    announcementPayload['mediaUri'] = announcement.mediaUri
                }

                const response = await updateAnnouncement(announcement); // Add parameters if needed
                if (response.success) {

                    setAnnouncementsData((state) => {
                        const findIdx = state.findIndex(item => item?.id === announcement.id);
                        if (!isNaN(findIdx)) {
                            state[findIdx] = announcement
                        }

                        return [...state]
                    });
                    toggleEditModal()

                    if (files.length) {
                        // request signed upload url
                        const uploads = [
                            {
                                resourcePath: `announcements/${valueToBeModified.id}`,
                                fileName: files[0]['name']
                            }
                        ]

                        const response = await uploadFiles(uploads);

                        if (response.success) {
                            const putUrl = response.data.uploads?.[0]?.putUrl

                            uploadImageOnS3(putUrl, files[0])
                                .then(() => {
                                    fetchAnnouncements();
                                });
                        }
                    }
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to update announcement.");
                }
            } catch (error) {

                toast.error("Failed to update announcement.");
            }
        };

        modifyAnnouncement();
    }

    const handleDeleteAnnouncement = () => {
        const removeAnnouncement = async () => {
            try {
                const response = await deleteAnnouncement(valueToBeModified.id); // Add parameters if needed
                if (response.success) {

                    setAnnouncementsData((state) => [...state.filter(item => item.id !== valueToBeModified.id)]);
                    toggleDeleteModal()
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to remove announcement.");
                }
            } catch (error) {

                toast.error("Failed to remove announcement.");
            }
        }

        if (!isNaN(valueToBeModified?.id)) {
            removeAnnouncement()
        }
    }

    useEffect(() => {
        fetchAnnouncements();
    }, []);

    const columns: column[] = useMemo(
        () => {
            const baseColumns: column[] = [
                {
                    header: 'ID',
                    accessorKey: 'id',
                    enableColumnFilter: false,
                    enableSorting: true,
                },
                {
                    header: 'Title',
                    accessorKey: 'title',
                    enableColumnFilter: false,
                    enableSorting: true,
                },
                {
                    header: 'Description',
                    accessorKey: 'description',
                    enableColumnFilter: false,
                    enableSorting: true,
                },
                {
                    header: 'Start At',
                    accessorKey: 'startDate',
                    cell: (info) => <span>{new Date(convertUTC2Local(info.getValue())).toLocaleDateString()}</span>,
                    enableColumnFilter: false,
                    enableSorting: true
                },
                {
                    header: 'Expire At',
                    accessorKey: 'expirationDate',
                    cell: (info) => <span>{convertUTC2Local(info.getValue()) ? new Date(convertUTC2Local(info.getValue())).toLocaleDateString() : ''}</span>,
                    enableColumnFilter: false,
                    enableSorting: true
                },
                // Add more base columns as needed
            ];

            if (!currentUser.isManager()) {
                baseColumns.push({
                    header: 'Action',
                    accessorKey: 'action',
                    enableColumnFilter: false,
                    enableSorting: true,
                    cell: (cellProps) => {
                        return (
                            <div className="d-flex gap-3">
                                <Link to="#" className="text-success" onClick={() => {
                                    setValueToBeModified(cellProps.row.original);
                                    toggleEditModal();
                                }}>
                                    <i className="mdi mdi-pencil font-size-18" id="editTooltip" />
                                    <UncontrolledTooltip placement="top" target="editTooltip">
                                        Edit
                                    </UncontrolledTooltip>
                                </Link>
                                <Link to="#" className="text-danger" onClick={() => {
                                    setValueToBeModified(cellProps.row.original);
                                    toggleDeleteModal();
                                }}>
                                    <i className="mdi mdi-delete font-size-18" id="deleteTooltip" />
                                    <UncontrolledTooltip placement="top" target="deleteTooltip">
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </div>
                        );
                    }
                });
            }

            return baseColumns;
        },
        [toggleNewModal, currentUser]
    );


    //meta title
    document.title = `${title} | Eckerd Connects - Admin`;

    return (
        <div className="page-content">
            <ToastContainer />
            <Container fluid>
                <Breadcrumbs title="List" breadcrumbItem={title}/>
                <Row>
                    <Col lg={12}>
                        <div >
                            {isLoading ? <Spinners setLoading={setLoading} /> :
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <TableContainer
                                                    columns={columns}
                                                    data={announcementsData || []}
                                                    isGlobalFilter={true}
                                                    isPagination={true}
                                                    isAddButton={!currentUser.isManager()}
                                                    isCustomPageSize={true}
                                                    handleUserClick={toggleNewModal}
                                                    SearchPlaceholder="Search anouncements..."
                                                    pagination="pagination"
                                                    buttonClass="btn btn-success btn-rounded"
                                                    buttonName=" Create New Announcement"
                                                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                                                    theadClass="table-light"
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            }
                            <AnnouncementModal isOpen={newModalIsOpen} toggle={toggleNewModal} onSave={handleCreateAnnouncement} />
                            <AnnouncementModal isOpen={editModalIsOpen} isEdit data={valueToBeModified} toggle={toggleEditModal} onSave={handleEditAnnouncement} />
                            <DeleteModal
                                isOpen={deleteModalIsOpen}
                                toggle={toggleDeleteModal}
                                message={<p>Are you sure you want to delete this announcement? This action cannot be undone.</p>}
                                onConfirmDelete={handleDeleteAnnouncement}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default AnouncementsPage;
