import React, {useEffect, useMemo, useState} from "react";

//import components
import Breadcrumbs from '../../../Components/Common/Breadcrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import { addResource, deleteResource, getResources, getSingleResourceCategory, updateResource, uploadFiles, uploadImageOnS3 } from "helpers/backend_helper";
import { toast, ToastContainer } from 'react-toastify';
import RoleCell from "../../../Components/Common/RoleCell";
import 'react-toastify/dist/ReactToastify.css';
import { Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Row, UncontrolledDropdown, UncontrolledTooltip } from "reactstrap";
import Spinners from "Components/Common/Spinner";
import DeleteModal from "Components/Modal/DeleteModal";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import ResourceModal from "Components/Modal/ResourceModal";
import { convertUTC2Local } from "utils";
import {userManager} from "../../../utils/UserManager";

interface column {
    header: string,
    accessorKey: string,
    enableColumnFilter: boolean,
    enableSorting: boolean,
    cell?: (cellProps: any) => JSX.Element;
}

const currentUser = userManager;
const ResourcesPage = (props) => {
    const [managersData, setManagersData] = useState([]);
    const params = useParams();
    const navigate = useNavigate();
    const [resourcesData, setResourcesData] = useState<any>([]);
    const [isLoading, setLoading] = useState<boolean>(true);
    const [newModalIsOpen, setNewModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [valueToBeModified, setValueToBeModified] = useState<any>();
    const [categoryData, setCategoryData] = useState<any>({});
    const { categoryId } = params;
    const location = useLocation();

    // Use URLSearchParams to extract query parameters
    const queryParams = new URLSearchParams(location.search);
    const resourceType = queryParams.get('resourceType'); // This will get the 'resourceType' param or return null
    function toggleNewModal() {
        setNewModalIsOpen(!newModalIsOpen);
        removeBodyCss();
    }

    function toggleEditModal() {
        setEditModalIsOpen(!editModalIsOpen);
        removeBodyCss();
    }

    function toggleDeleteModal () {
        setDeleteModalIsOpen(!deleteModalIsOpen);
        removeBodyCss();
    }

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    const handleCreateResource = (newResource: any, files: File[] = []) => {
        const createResource = async () => {
            try {
                const resourcePayload = {
                    title: newResource.title,
                    description: newResource.description
                }

                if (newResource.mediaUri) {
                    resourcePayload['mediaUri'] = newResource.mediaUri
                } else {
                    resourcePayload['mediaUri'] = null
                }

                const response = await addResource(Number(categoryId), resourcePayload); // Add parameters if needed
                if (response.success) {

                    setResourcesData((state) => [...state, response.data]);
                    const resourceId = response.data.id;

                    if (files.length) {
                        // request signed upload url
                        const uploads = [
                            {
                                resourcePath: `resources/${resourceId}`,
                                fileName: files[0]['name']
                            }
                        ]

                        const response = await uploadFiles(uploads);

                        if (response.success) {
                            const putUrl = response.data.uploads?.[0]?.putUrl

                            uploadImageOnS3(putUrl, files[0])
                                .then(() => {
                                    fetchResources(categoryId);
                                });
                        }
                    }
                    toast.success("Created new resource.");
                    toggleNewModal();
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to create resource.");
                }
            } catch (error) {

                toast.error("Failed to create resource.");
            }
        };

        createResource();
    }

    const handleEditResource = (resource: any, files: File[]) => {
        const modifyResource = async () => {
            try {
                const resourcePayload = {
                    id: resource.id,
                    title: resource.title,
                    description: resource.description
                }

                if (resource.mediaUri) {
                    resourcePayload['mediaUri'] = resource.mediaUri
                } else {
                    resourcePayload['mediaUri'] = null
                }

                const response = await updateResource(Number(categoryId), resourcePayload); // Add parameters if needed
                if (response.success) {

                    setResourcesData((state) => {
                        const findIdx = state.findIndex(item => item?.id === resource.id);
                        if (!isNaN(findIdx)) {
                            state[findIdx] = resource
                        }

                        return [...state]
                    });
                    toggleEditModal()

                    if (files.length) {
                        // request signed upload url
                        const uploads = [
                            {
                                resourcePath: `resources/${valueToBeModified.id}`,
                                fileName: files[0]['name']
                            }
                        ]

                        const response = await uploadFiles(uploads);

                        if (response.success) {
                            const putUrl = response.data.uploads?.[0]?.putUrl

                            uploadImageOnS3(putUrl, files[0])
                                .then(() => {
                                    fetchResources(categoryId);
                                });
                        }
                    }
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to update resource.");
                }
            } catch (error) {

                toast.error("Failed to update resource.");
            }
        };

        modifyResource();
    }

    const handleDeleteResource = () => {
        const removeResource = async () => {
            try {
                const response = await deleteResource(Number(categoryId), valueToBeModified.id); // Add parameters if needed
                if (response.success) {

                    setResourcesData((state) => [...state.filter(item => item.id !== valueToBeModified.id)]);
                    toggleDeleteModal()
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to remove resource.");
                }
            } catch (error) {

                toast.error("Failed to remove resource.");
            }
        }

        if (!isNaN(valueToBeModified?.id)) {
            removeResource()
        }
    }

    const fetchResources = async (categoryId) => {
        try {
            const response = await getResources(Number(categoryId), 1,0,50,resourceType); // Add parameters if needed
            if (response.success) {

                setResourcesData(response.data.resources);
            } else {
                // Display error notification
                toast.error(response.error || "Failed to fetch resources.");
            }
        } catch (error) {

            toast.error("Failed to fetch resources.");
        }
    };

    useEffect(() => {
        // fetchManagers();
        if (!isNaN(Number(categoryId))) {
          fetchResources(categoryId);
        }
    }, [categoryId]);

    const handleFileUpload = async (files: File[]) => {
        if (files.length) {
            const uploads = [
                {
                    resourcePath: `resources/${valueToBeModified.id}`,
                    fileName: files[0]['name']
                }
            ]

            const response = await uploadFiles(uploads);

            if (response.success) {
                const putUrl = response.data.uploads?.[0]?.putUrl

                try {
                    uploadImageOnS3(putUrl, files[0])
                } catch (e) {

                }
            }
        }
    }

    const breadcrumbItems: any[] = useMemo(
      () => [
        {
          link: '/resources',
          title: 'Resource Category'
        }
      ],
      []
    );

    useEffect(() => {
        if (!isNaN(Number(categoryId))) {
            getSingleResourceCategory(Number(categoryId),resourceType)
                .then((res) => {
                    setCategoryData(res.data);
                })
        }
    }, [categoryId]);

    const columns: column[] = useMemo(
        () => {
            const baseColumns:column[] = [
                {
                    header: 'ID',
                    accessorKey: 'id',
                    enableColumnFilter: false,
                    enableSorting: true,
                },
                {
                    header: 'Title',
                    accessorKey: 'title',
                    enableColumnFilter: false,
                    enableSorting: true,
                },
                {
                    header: 'Description',
                    accessorKey: 'description',
                    enableColumnFilter: false,
                    enableSorting: true,
                },
                {
                    header: 'Resource Uri',
                    accessorKey: 'mediaUri',
                    enableColumnFilter: false,
                    enableSorting: true,
                    cell: ({ cell }) => {
                        const uri = cell.getValue();
                        if (!uri) return (<></>); // If there's no value, render an empty cell
                        // Get the base URI part before the '?' sign
                        const baseUri = uri.split('?')[0];

                        return (
                            <a
                                href={uri}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: 'blue',
                                    textDecoration: 'underline',
                                    wordWrap: 'break-word',
                                    wordBreak: 'break-all',
                                    whiteSpace: 'pre-wrap',
                                    maxWidth: '300px'  // Adjust maxWidth based on the column size
                                }}
                            >
                                {baseUri}
                            </a>
                        );
                    },
                },


                // Add more base columns as needed
            ];
            const isAdminOrManager =  currentUser.isAdmin(); // Check if the user is either admin or manager
            const isCorp = resourceType === 'corp'; // Check if the resource type is 'corp'
            const isCorporateUser = currentUser.isCorporate(); // Assuming there's a method to check if the user is corporate
            if (isCorporateUser || (isAdminOrManager && !isCorp)) {
                baseColumns.push({
                    header: 'Action',
                    accessorKey: 'action',
                    enableColumnFilter: false,
                    enableSorting: true,
                    cell: (cellProps) => {
                        return (
                            <div className="d-flex gap-3">
                                <button className="btn text-success p-0" onClick={() => {
                                    setValueToBeModified(cellProps.row.original);
                                    toggleEditModal();
                                }}>
                                    <i className="mdi mdi-pencil font-size-18" id="editTooltip"/>
                                    <UncontrolledTooltip placement="top" target="editTooltip">
                                        Edit
                                    </UncontrolledTooltip>
                                </button>
                                <button className="btn text-danger p-0" onClick={() => {
                                    setValueToBeModified(cellProps.row.original);
                                    toggleDeleteModal();
                                }}>
                                    <i className="mdi mdi-delete font-size-18" id="deleteTooltip"/>
                                    <UncontrolledTooltip placement="top" target="deleteTooltip">
                                        Delete
                                    </UncontrolledTooltip>
                                </button>
                            </div>
                        );
                    }
                });
            }

            return baseColumns;
        },
        [toggleNewModal, currentUser]
    );


    //meta title
    document.title = `${props.title} | Eckerd Connects - Admin`;

    return (
        <div className="page-content">
            <ToastContainer/>
            <Container fluid>
                <Breadcrumbs title="List" items={breadcrumbItems}
                             breadcrumbItem={(categoryData.name ?? '') + ' Resources'}/>
                <Row>
                    <Col lg={12}>
                        <div >
                            {isLoading ? <Spinners setLoading={setLoading} /> :
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <TableContainer
                                                    columns={columns}
                                                    data={resourcesData || []}
                                                    isGlobalFilter={true}
                                                    isPagination={true}
                                                    isAddButton={!currentUser.isManager()}
                                                    isCustomPageSize={true}
                                                    handleUserClick={toggleNewModal}
                                                    SearchPlaceholder="Search resources..."
                                                    pagination="pagination"
                                                    buttonClass="btn btn-success btn-rounded"
                                                    buttonName=" Create New Resource"
                                                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                                                    theadClass="table-light"
                                                    divClassName=""
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            }
                            <ResourceModal isOpen={newModalIsOpen} toggle={toggleNewModal} onSave={handleCreateResource} onUploadFile={handleFileUpload} />
                            <ResourceModal isOpen={editModalIsOpen} isEdit data={valueToBeModified} toggle={toggleEditModal} onSave={handleEditResource} onUploadFile={handleFileUpload} />
                            <DeleteModal
                                isOpen={deleteModalIsOpen}
                                toggle={toggleDeleteModal}
                                message={<p>Are you sure you want to delete this resource? This action cannot be undone.</p>}
                                onConfirmDelete={handleDeleteResource}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default ResourcesPage;
