import React, { useEffect, useMemo, useState } from "react";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


//import components
import Breadcrumbs from '../../../Components/Common/Breadcrumb';
import TableContainer from '../../../Components/Common/TableContainer';
import {deleteAdmin, deleteInvite, getInvitations, getTenants, resendInvitation} from "helpers/backend_helper";
import { UserRoleKeys, mapRoleToEnum, userManager } from "../../../utils/UserManager";
import RoleCell from "../../../Components/Common/RoleCell";
import BadgeCell from "../../../Components/Common/BadgeCell";
import { Card, CardBody, Col, Container, Row, UncontrolledTooltip } from "reactstrap";
import Spinners from "Components/Common/Spinner";
import { Link } from "react-router-dom";
import ConfirmModal from "Components/Modal/ConfirmModal";
import { convertUTC2Local } from "utils";
import DeleteModal from "../../../Components/Modal/DeleteModal";

interface column {
    header: string,
    accessorKey: string,
    enableColumnFilter: boolean,
    enableSorting: boolean,
}



const InvitationsPage = ({title}) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [invitationsData, setInvitationsData] = useState<any[]>([]);
    const [invitationStatus, setInvitationStatus] = useState(1);
    const [valueToBeModified, setValueToBeModified] = useState<any>();
    const [tenantFilterId, setTenantFilterId] = useState<string>();
    const [tenantsData, setTenantsData] = useState<any[]>([]);
    const role = userManager.getUserRole();
    const [invitationRole, setInvitationRole] = useState<UserRoleKeys>();
    const [resendModalIsOpen, setResendModalIsOpen] = useState(false);
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    function toggleResendModal () {
        setResendModalIsOpen(!resendModalIsOpen);
        removeBodyCss();
    }
    const handleDeleteInvite = () => {
        const removeAdmin = async () => {
            try {
                const response = await deleteInvite(valueToBeModified.id, valueToBeModified.role ?? '', tenantFilterId ?? ''); // Add parameters if needed
                if (response.success) {
                    // Update invitationsData by filtering out the deleted invite
                    setInvitationsData((prevInvitations) =>
                        prevInvitations.filter(invite => invite.id !== valueToBeModified.id)
                    );

                    toast.success('Invitation successfully deleted!');
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to remove admin.");
                }
            } catch (error) {
                toast.error("Failed to remove admin.");
            }
        };

        if (!isNaN(valueToBeModified?.id)) {
            removeAdmin();
        }
    };

    function removeBodyCss() {
        document.body.classList.add("no_padding");
    }

    useEffect(() => {
        setInvitationRole(role === UserRoleKeys.RoleAdmin ? UserRoleKeys.RoleManager : (role === UserRoleKeys.RoleCorp ? UserRoleKeys.RoleCorp : UserRoleKeys.RoleNone))
    }, [role]);

    useEffect(() => {
        const fetchTenants = async () => {
            try {
                const response = await getTenants(1); // Add parameters if needed
                if (response.success) {

                    setTenantsData(response.data.tenants);

                    if (response.data.tenants && response.data.tenants?.[0]) {
                        setTenantFilterId(response.data.tenants[0].dbName)
                    }
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to fetch tenants.");
                }
            } catch (error) {

                toast.error("Failed to fetch tenants.");
            }
        };

        if (role === UserRoleKeys.RoleCorp) {
            fetchTenants();
        }
    }, [role]);

    useEffect(() => {
        const fetchInvitations = async () => {
            try {
                const tenantId = (role === UserRoleKeys.RoleCorp && invitationRole === UserRoleKeys.RoleAdmin) ? tenantFilterId : undefined;

                const response = await getInvitations(invitationRole, tenantId, invitationStatus); // Add parameters if needed
                if (response.success) {
                    setInvitationsData(response.data.invites);
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to fetch invitations.");
                }
            } catch (error) {
                toast.error( "Failed to fetch invitations.");
            }
        };
        fetchInvitations();
    }, [invitationStatus, invitationRole, role, tenantFilterId]);

    const statusRadioGroups = useMemo(() => {
        if (role === 'role_admin') {
            return (
                <Row className="justify-content-end" xs="auto">
                    <Col className="btn-group" role="role">
                        <input type="radio" className="btn-check" name="role" id="role_manager" autoComplete="off" defaultChecked onClick={() => setInvitationRole(UserRoleKeys.RoleManager)} />
                        <label className="btn btn-outline-secondary" htmlFor="role_manager">Manager</label>

                        <input type="radio" className="btn-check" name="role" id="role_client" autoComplete="off" onClick={() => setInvitationRole(UserRoleKeys.RoleNone)} />
                        <label className="btn btn-outline-secondary" htmlFor="role_client">Client</label>
                    </Col>
                    <Col className="btn-group" role="status">
                        <input type="radio" className="btn-check" name="status" id="accepted" autoComplete="off" defaultChecked onClick={() => setInvitationStatus(1)} />
                        <label className="btn btn-outline-secondary" htmlFor="accepted">Accepted</label>

                        <input type="radio" className="btn-check" name="status" id="notaccepted" autoComplete="off" onClick={() => setInvitationStatus(0)} />
                        <label className="btn btn-outline-secondary" htmlFor="notaccepted">Not Accepted</label>
                    </Col>
                </Row>
            )
        } else if (role === 'role_corp') {
            return (
                <Row className="justify-content-end" xs="auto">
                    <Col className="btn-group" role="role">
                        <input type="radio" className="btn-check" name="role" id="role_corp" autoComplete="off" defaultChecked onClick={() => setInvitationRole(UserRoleKeys.RoleCorp)} />
                        <label className="btn btn-outline-secondary" htmlFor="role_corp">Corporate</label>

                        <input type="radio" className="btn-check" name="role" id="role_admin" autoComplete="off" onClick={() => setInvitationRole(UserRoleKeys.RoleAdmin)} />
                        <label className="btn btn-outline-secondary" htmlFor="role_admin">Administrator</label>
                    </Col>
                    <Col className="btn-group" role="status">
                        <input type="radio" className="btn-check" name="btnradio" id="accepted" autoComplete="off" defaultChecked onClick={() => setInvitationStatus(1)} />
                        <label className="btn btn-outline-secondary" htmlFor="accepted">Accepted</label>

                        <input type="radio" className="btn-check" name="btnradio" id="notaccepted" autoComplete="off" onClick={() => setInvitationStatus(0)} />
                        <label className="btn btn-outline-secondary" htmlFor="notaccepted">Not Accepted</label>
                    </Col>
                </Row>
            )
        } else {
            return (
                <Row className="justify-content-end" xs="auto">
                    <Col className="btn-group" role="status">
                        <input type="radio" className="btn-check" name="btnradio" id="accepted" autoComplete="off" defaultChecked onClick={() => setInvitationStatus(1)} />
                        <label className="btn btn-outline-secondary" htmlFor="accepted">Accepted</label>

                        <input type="radio" className="btn-check" name="btnradio" id="notaccepted" autoComplete="off" onClick={() => setInvitationStatus(0)} />
                        <label className="btn btn-outline-secondary" htmlFor="notaccepted">Not Accepted</label>
                    </Col>
                </Row>
            )
        }
    }, [role]);

    const customLeftTools = useMemo(() => {
        if (role === UserRoleKeys.RoleCorp && invitationRole === UserRoleKeys.RoleAdmin) {
            return (
                <React.Fragment>
                    <select className="form-select form-control" value={tenantFilterId ?? 'Select'} onChange={(e) => {
                        setTenantFilterId(e.target.value)
                    }}>
                    <option>Select</option>
                    {tenantsData?.map(tenant => (
                        <option key={tenant.id} value={tenant.dbName}>{tenant.name}</option>
                    ))}
                    </select>
                </React.Fragment>
            )
        } else {
            return <React.Fragment />
        }
    }, [tenantFilterId, tenantsData, invitationRole, role]);

    const columns: column[] = useMemo(
        () => {
            const columns = [
                {
                    header: 'Invite ID',
                    accessorKey: 'id',
                    enableColumnFilter: false,
                    enableSorting: true,
                },
                {
                    header: 'Email Invited',
                    accessorKey: 'emailInvited',
                    enableColumnFilter: false,
                    enableSorting: true,
                },
                {
                    header: 'Invited Name',
                    accessorKey: 'nameInvited',
                    enableColumnFilter: false,
                    enableSorting: true,
                },
                {
                    header: 'Role',
                    accessorKey: 'role',
                    cell: (info) => <RoleCell value={info.getValue()} />,
                    enableColumnFilter: false,
                    enableSorting: true,
                },
                {
                    header: 'Status',
                    accessorKey: 'status',
                    cell: (info) => <BadgeCell value={info.getValue() ? "Accepted" : "Not accepted"} />,
                    enableColumnFilter: false,
                    enableSorting: true,
                },
                {
                    header: 'Sent By',
                    accessorKey: 'sentBy',
                    enableColumnFilter: false,
                    enableSorting: true,
                },


                // Add more columns as needed
            ]

            if (invitationStatus === 0) {
                columns.push(
                    {
                        header: 'Expiration Date',
                        accessorKey: 'expirationDate',
                        cell: (cellProps) => {
                            return (
                                <div className="d-flex gap-3">
                                    {convertUTC2Local(cellProps.getValue())}
                                </div>
                            )
                        },
                        enableColumnFilter: false,
                        enableSorting: true,
                    },
                    {
                    header: 'Action',
                    accessorKey: 'action',
                    enableColumnFilter: false,
                    enableSorting: true,
                    cell: (cellProps) => {
                        return (
                            <div className="d-flex gap-3">
                                <Link to="#" className="text-primamry" onClick={() => {
                                    setValueToBeModified(cellProps.row.original)
                                    toggleResendModal()
                                }}>
                                <i className="mdi mdi-send font-size-18" id="resendTooltip" />
                                <UncontrolledTooltip placement="top" target="resendTooltip">
                                    Resend
                                </UncontrolledTooltip>
                                </Link>
                                <Link to="#" className="text-danger" onClick={() => {
                                    setValueToBeModified(cellProps.row.original)
                                    toggleDeleteModal()
                                }}>
                                    <i className="mdi mdi-delete font-size-18" id="deleteTooltip" />
                                    <UncontrolledTooltip placement="top" target="deleteTooltip">
                                        Delete
                                    </UncontrolledTooltip>
                                </Link>
                            </div>
                        )
                    }
                })
            }
            return columns;
        },
        [invitationStatus]
    );
    function toggleDeleteModal () {
        setDeleteModalIsOpen(state => !state);
        removeBodyCss();
    }

    const handleConfirmResend = () => {
        toggleResendModal();
        const resendInvite = async () => {
            try {
                const response = await resendInvitation(valueToBeModified.role, valueToBeModified.id, (role === UserRoleKeys.RoleCorp && invitationRole === UserRoleKeys.RoleAdmin) ? tenantFilterId : null); // Add parameters if needed
                if (response.success) {

                    const newInvite = response.data;

                    setInvitationsData((state) => {
                        const findIdx = state.findIndex(item => item?.id === newInvite.id);
                        if (!isNaN(findIdx)) {
                            state[findIdx] = newInvite
                        }

                        return [...state]
                    });

                    toast.success(`You resent invitation to ${newInvite.emailInvited}`, { autoClose: 2000 })
                } else {
                    // Display error notification
                    toast.error(response.error || "Failed to resend invitation.");
                }
            } catch (error) {

                toast.error("Failed to resend invitation.");
            }
        }

        if (!isNaN(valueToBeModified?.id)) {
            resendInvite()
        }
    }

    //meta title
    document.title = `${title} | Eckerd Connects - Admin`;



    return (
        <div className="page-content">
             <ToastContainer />
             <Container fluid>
                <Breadcrumbs title="List" breadcrumbItem={title}/>
                <Row>
                    <Col lg={12}>
                        <div >
                            {isLoading ? <Spinners setLoading={setLoading} /> :
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <CardBody>
                                                <TableContainer
                                                    columns={columns}
                                                    data={invitationsData || []}
                                                    isGlobalFilter={true}
                                                    isPagination={true}
                                                    isCustomPageSize={true}
                                                    SearchPlaceholder="Search invitations..."
                                                    pagination="pagination"
                                                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                                                    theadClass="table-light"
                                                    customLeftTools={
                                                        customLeftTools
                                                    }
                                                    customRightTools={
                                                        statusRadioGroups
                                                    }
                                                />
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            }
                            <ConfirmModal
                                isOpen={resendModalIsOpen}
                                toggle={toggleResendModal}
                                message={<p>Are you sure you want to resend the invitation?</p>}
                                onConfirm={handleConfirmResend}
                            />
                            <DeleteModal
                                isOpen={deleteModalIsOpen}
                                toggle={toggleDeleteModal}
                                message={<p>Are you sure you want to delete this invitation? This action cannot be undone.</p>}
                                onConfirmDelete={handleDeleteInvite}
                            />
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
export default InvitationsPage;
