import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";

import UserChat from "../../../slices/chat/ChatWindow";

//Import Breadcrumb
import Breadcrumbs from "../../../Components/Common/Breadcrumb";

const ChatPage: React.FC<{}> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //meta title
  document.title = `Chat | Admin App Support`;
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Admin Support" breadcrumbItem="Chat" />

          <Row>
            <Col lg={12}>
              <div className="d-lg-flex">

                <UserChat
                  Chat_Box_Username="Admin Support"
                  Chat_Box_User_Status="online"
                  messages={[]}
                  loading={isLoading}
                  chatType="support"
                  client={{}}
                />

              </div>
            </Col>
          </Row>
        </Container>
      </div >
    </React.Fragment >
  );
};



export default ChatPage;
