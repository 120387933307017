import FileUploader from 'Components/Common/FileUploader';
import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { convertUTCDateToInputFormat, dayAfter, generalizeValue } from 'utils';
import Flatpickr from 'react-flatpickr';
import "flatpickr/dist/themes/material_blue.css";

type IAnnouncementModal = {
  data?: any;
  isOpen: boolean;
  isEdit?: boolean;
  onSave?: Function;
  toggle: () => void
}

const AnnouncementModal: React.FC<IAnnouncementModal> = ({ data, isOpen, isEdit, onSave, toggle }) => {
  const [newData, setNewData] = useState<any>({});
  const [announcementFiles, setAnnouncementFiles] = useState<File[]>([]);

  const toggleW = () => {
    toggle && toggle()
  }

  const handleSave = () => {
    const payload = {...newData};

    if (!payload.startDate) {
      const value =  new Date();

      const date = new Date(value);
      const normalizedDateStr = '' + date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + '00:00:00'
      const currentDate = new Date(normalizedDateStr);
      const year = currentDate.getUTCFullYear();
      const month = String(currentDate.getUTCMonth() + 1).padStart(2, '0');
      const day = String(currentDate.getUTCDate()).padStart(2, '0');
      const hour = String(currentDate.getUTCHours()).padStart(2, '0');
      const minute = String(currentDate.getUTCMinutes()).padStart(2, '0');
      const second = String(currentDate.getUTCSeconds()).padStart(2, '0');
 
      payload['startDate'] = `${year}-${month}-${day} ${hour}:${minute}:${second}`
    }

    if (!payload.expirationDate || isNaN(new Date(payload.expirationDate).getTime())) {
      payload['expirationDate'] = null
    }
    onSave && onSave(payload, announcementFiles)
  }

  const handleFieldInput = (e) => {
    const generalizedValue = generalizeValue(e.target.type, e.target.value)

    setNewData((state) => ({
      ...state,
      [e.target.id]: generalizedValue
    }))
  }

  const handleStartInputField = (value) => {
    const date = new Date(value);
    const normalizedDateStr = '' + date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + '00:00:00'
    const currentDate = new Date(normalizedDateStr);
    const year = currentDate.getUTCFullYear();
    const month = String(currentDate.getUTCMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getUTCDate()).padStart(2, '0');
    const hour = String(currentDate.getUTCHours()).padStart(2, '0');
    const minute = String(currentDate.getUTCMinutes()).padStart(2, '0');
    const second = String(currentDate.getUTCSeconds()).padStart(2, '0');

    setNewData((state) => ({
      ...state,
      "startDate": `${year}-${month}-${day} ${hour}:${minute}:${second}`
    }))
  }

  const handleEndInputField = (value) => {
    const date = new Date(value);
    const normalizedDateStr = '' + date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + '23:59:59'
    const currentDate = new Date(normalizedDateStr);
    const year = currentDate.getUTCFullYear();
    const month = String(currentDate.getUTCMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getUTCDate()).padStart(2, '0');
    const hour = String(currentDate.getUTCHours()).padStart(2, '0');
    const minute = String(currentDate.getUTCMinutes()).padStart(2, '0');
    const second = String(currentDate.getUTCSeconds()).padStart(2, '0');

    setNewData((state) => ({
      ...state,
      "expirationDate": `${year}-${month}-${day} ${hour}:${minute}:${second}`
    }))
  }

  const handleUploadResouceFiles = (files: File[]) => {

  }

  const handleResourceFileChange = (files: File[]) => {
    setAnnouncementFiles(files)
  }

  useEffect(() => {
    if (isEdit) {
      setNewData({...data})
    } else {
      setNewData({})
    }
  }, [data, isEdit, isOpen])

  return (
      <Modal isOpen={isOpen} toggle={toggleW}>
          <ModalHeader toggle={toggleW}>
              <div className="modal-title mt-0 h5" id="inviteModalLabel">
                  {isEdit ? "Update Announcement" : "New Announcement" }
              </div>
          </ModalHeader>
          <ModalBody>
              <form>
                  <div className="mb-3">
                      <label htmlFor="title" className="form-label">Title</label>
                      <input value={newData?.title} type="text" className="form-control" id="title" placeholder="Enter title" onChange={handleFieldInput} />
                  </div>
                  <div className="mb-3">
                      <label htmlFor="description" className="form-label">Description</label>
                      <input value={newData?.description} type="text" className="form-control" id="description" placeholder="Enter description" onChange={handleFieldInput} />
                  </div>
                  <div className="mb-3">
                      <label htmlFor="startDate" className="form-label">Start At</label>
                      <Flatpickr
                        type="date"
                        id="startDate"
                        name="startDate"
                        className="form-control"
                        options={{ minDate: isEdit ? undefined : convertUTCDateToInputFormat(null)}}
                        value={convertUTCDateToInputFormat(newData?.startDate)}
                        onChange={handleStartInputField}
                      />
                  </div>
                  <div className="mb-3">
                      <label htmlFor="expirationDate" className="form-label">Expire At</label>
                      <Flatpickr
                        type="date"
                        id="expirationDate"
                        name="expirationDate"
                        className="form-control"
                        options={{ minDate: dayAfter(convertUTCDateToInputFormat(newData?.startDate))}}
                        value={newData?.expirationDate ? convertUTCDateToInputFormat(newData?.expirationDate) : ''}
                        onChange={handleEndInputField}
                      />
                  </div>
                  <div className="mb-3">
                      <label htmlFor="mediaUri" className="form-label">Media URI</label>
                      <input value={newData?.mediaUri} type="url" className="form-control" id="mediaUri" placeholder="Enter media url or upload resource file" onChange={handleFieldInput} />
                  </div>
                  <FileUploader label="Announcement Files" hint="Please upload files." multiple={false} onSend={handleUploadResouceFiles} onChange={handleResourceFileChange} />
                  <p>Please fill out the form to create new announcement.</p>
              </form>
          </ModalBody>
          <ModalFooter>
              <button
                  type="button"
                  onClick={toggleW}
                  className="btn btn-secondary"
                  data-dismiss="modal"
              >
                  Close
              </button>
              <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSave}
              >
                  {isEdit ? "Update announcement" : "Create announcement"}
              </button>
          </ModalFooter>
      </Modal>
  );
};

export default AnnouncementModal;
