import React, { useState } from "react";
import { Input, TabContent, TabPane } from "reactstrap";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
import Spinners from "Components/Common/Spinner";

interface Props {
    userChatOpen: any;
    clients: any[];
    currentClient: any;
    clientsLoading: boolean;
}

const ChatList: React.FC<Props> = ({ clients, currentClient, userChatOpen, clientsLoading }) => {

    const [isLoading, setLoading] = useState(false);//loading)

    //search recent user
    const searchUsers = () => {
        var input: any, filter: any, ul: any, li: any, a: any, i: any, txtValue: any;
        input = document.getElementById("search-user");
        filter = input.value.toUpperCase();
        ul = document.getElementById("recent-list");
        li = ul.getElementsByTagName("li");
        for (i = 0; i < li.length; i++) {
            a = li[i].getElementsByTagName("a")[0];
            txtValue = a.textContent || a.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
                li[i].style.display = "";
            } else {
                li[i].style.display = "none";
            }
        }
    };

    return (
        <React.Fragment>
            <div className="chat-leftsidebar me-lg-4">
                <div >

                    <div className="search-box chat-search-box py-4">
                        <div className="position-relative">
                            <Input onKeyUp={searchUsers} id="search-user" type="text" placeholder="Search..." onChange={searchUsers} />
                            <i className="bx bx-search-alt search-icon" />
                        </div>
                    </div>

                    <div className="chat-leftsidebar-nav">
                        <TabContent activeTab="chat" className="py-4">
                            <TabPane tabId="chat">
                                <div>
                                    <h5 className="font-size-14 mb-3">Clients</h5>
                                    <ul className="list-unstyled chat-list position-relative" id="recent-list">
                                        {
                                            clientsLoading ? <Spinners setLoading={setLoading} /> :
                                                <SimpleBar style={{ height: "410px" }}>
                                                    {(clients || [])?.map((client: any) => (
                                                        <li key={client.id} className={"active" ?? ""}>
                                                            <Link to="#" onClick={() => userChatOpen(client)}>
                                                                <div className="d-flex">
                                                                    <div className="align-self-center me-3">
                                                                        <i className={`mdi mdi-circle font-size-10 ${client.id === currentClient.id ? "text-success" : ""}`} />
                                                                    </div>
                                                                    <div className="flex-grow-1 overflow-hidden">
                                                                        <h5 className="text-truncate font-size-14 mb-1">
                                                                            {client.name}
                                                                        </h5>
                                                                        {/* <p className="text-truncate mb-0">{chat.description}</p> */}
                                                                    </div>
                                                                    {/* <div className="font-size-11">{chat.time}</div> */}
                                                                </div>
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </SimpleBar>
                                        }
                                    </ul>
                                </div>
                            </TabPane>
                        </TabContent>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ChatList;