import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import ChatList from "../../../slices/chat/ChatList";
import UserChat from "../../../slices/chat/ChatWindow";
import { toast } from 'react-toastify';

//Import Breadcrumb
import Breadcrumbs from "../../../Components/Common/Breadcrumb";

//redux
import { getClientChatHistory, getClients } from "../../../helpers/backend_helper";
import { userManager } from "utils/UserManager";

interface FilteredChatHistories {
  likes: any[],
  dislikes: any[],
  interventions: any[]
}

const ChatHistoryPage: React.FC<{}> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [clientsLoading, setClientsLoading] = useState<boolean>(false);
  const [clients, setClients] = useState<any[]>([]);
  const [currentClient, setCurrentClient] = useState<any>({});
  const [chatHistory, setChatHistory] = useState<any[]>([]);
  const [filteredChats, setFilteredChats] = useState<FilteredChatHistories>({
    likes: [],
    dislikes: [],
    interventions: []
  });

  //meta title
  document.title = `Chat | Chat History`;

  const currentUser = userManager;

  const fetchClients = async () => {
    try {
        setClientsLoading(true);
        // Ensure currentUser and dbUser are defined and have a groupIds array
        const user = currentUser.getUser();
        const userGroupId = user?.dbUser?.groupIds?.[0];

        const response = await getClients(1, 0, 20, userGroupId); // Add parameters if needed
        if (response.success) {

            setClients(response.data.clients);
            const firstClient = response.data.clients[0];
            if (firstClient) {
              setCurrentClient(firstClient);
            }
        } else {
            // Display error notification
            toast.error(response.error || "Failed to fetch clients.");
        }
    } catch (error) {
        toast.error("Failed to fetch clients.");
    }
    setClientsLoading(false);
  };

  const fetchChatHistory = async () => {
    try {
      const currentClientId = currentClient?.id;
      if (currentClientId) {
        const response = await getClientChatHistory(currentClientId);
        const newChatHistory = response.chat_history;
        if (newChatHistory) {
          setChatHistory(newChatHistory);
          parseFilteredChats(newChatHistory);
        } else {
          toast.error(response.error || "Failed to fetch client chat history");
        }
      }
    } catch (err) {
      toast.error("Failed to fetch client chat history");
    }
  };

  const parseFilteredChats = (chatHistory: any[]) => {
    const likeSnippets: any[] = [];
    const dislikeSnippets: any[] = [];
    const interventionSnippets: any[] = [];

    for (let i=0; i<chatHistory.length; i++) {
      if(chatHistory[i]?.metadata?.guardrail_interventions) {
        if (chatHistory[i-1]) {
          interventionSnippets.push(chatHistory[i-1])
        }
        interventionSnippets.push(chatHistory[i]);
        if (chatHistory[i+1]) {
          interventionSnippets.push(chatHistory[i+1]);
        }
      }
      if (chatHistory[i+1]?.metadata?.liked_at) {
        if (chatHistory[i]) {
          likeSnippets.push(chatHistory[i]);
        }
        likeSnippets.push(chatHistory[i+1]);
      }
      if (chatHistory[i+1]?.metadata?.disliked_at) {
        if (chatHistory[i]) {
          dislikeSnippets.push(chatHistory[i]);
        }
        dislikeSnippets.push(chatHistory[i+1]);
      }
    }
    const filteredHistories: FilteredChatHistories = {
      likes: likeSnippets,
      dislikes: dislikeSnippets,
      interventions: interventionSnippets
    };
    setFilteredChats(filteredHistories);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchClients();
    setIsLoading(false);
  }, []);
  useEffect(() => {
    setIsLoading(true);
    fetchChatHistory();
    setIsLoading(true);
  }, [currentClient]);

  const userChatOpen = (client: any) => {
    setCurrentClient(client);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Chat History" breadcrumbItem="Chat" />

          <Row>
            <Col lg={12}>
              <div className="d-lg-flex">

                <ChatList
                  userChatOpen={userChatOpen}
                  currentClient={currentClient}
                  clients={clients}
                  clientsLoading={clientsLoading}
                />

                <UserChat
                  Chat_Box_Username={currentClient?.name ?? "Chat History"}
                  messages={chatHistory}
                  filteredMessages={filteredChats}
                  loading={isLoading}
                  chatType="chatbotHistory"
                  client={currentClient}
                />

              </div>
            </Col>
          </Row>
        </Container>
      </div >
    </React.Fragment >
  );
};



export default ChatHistoryPage;
